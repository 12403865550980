import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import i18n from '@/libs/i18n'

Vue.use(VueRouter)
// code splitting: https://www.kaliop.com/en/blog-how-to-use-webpack-code-splitting-with-vue-js/
// https://webpack.js.org/api/module-methods/#import-
const Support = () => import(/* webpackChunkName: "app.support" */ '@/views/contacts/Support.vue')
const OrganizationBookingPage = () => import(/* webpackChunkName: "app.org-booking-page" */ '@/views/dashboard/booking-page/BookingPage.vue')
const InviteLoading = () => import(/* webpackChunkName: "app.invite-loading" */ '@/views/dashboard/workspaces/access/InviteLoading.vue')
const Account = () => import(/* webpackChunkName: "app.account" */ '@/views/account/Account.vue')
const Analytics = () => import(/* webpackChunkName: "app.dashboard" */ '@/views/dashboard/analytics/Analytics.vue')
const Workspaces = () => import(/* webpackChunkName: "app.workspaces" */ '@/views/dashboard/workspaces/Workspaces.vue')
const Records = () => import(/* webpackChunkName: "app.records" */ '@/views/dashboard/records/Records.vue')
const Services = () => import(/* webpackChunkName: "app.services" */ '@/views/dashboard/services/Services.vue')
const ClientBase = () => import(/* webpackChunkName: "app.client-base" */ '@/views/dashboard/client-base/ClientBase.vue')
const Widget = () => import(/* webpackChunkName: "app.widget" */ '@/views/dashboard/widget/Main.vue')
const Reviews = () => import(/* webpackChunkName: "app.feedbacks" */ '@/views/dashboard/reviews/Reviews.vue')
const Settings = () => import(/* webpackChunkName: "app.settings" */ '@/views/dashboard/settings/Settings.vue')
const BranchOffices = () => import(/* webpackChunkName: "app.branch-offices" */ '@/views/dashboard/branch-offices/BranchOffices.vue')
const PaymentSettings = () => import('@/views/dashboard/payments/settings/PaymentSettings.vue')
const PaymentHistory = () => import('@/views/dashboard/payments/transactions/Transactions.vue')
const WorkspacePage = () => import(/* webpackChunkName: "app.workspaces-page" */ '@/views/account/permitted-access/WorkspacePage.vue')
const Login = () => import(/* webpackChunkName: "app.login" */ '@/views/authentication/login/Login.vue')
const Register = () => import(/* webpackChunkName: "app.register" */ '@/views/authentication/registration/Register.vue')
const ForgotPassword = () => import(/* webpackChunkName: "app.forgot-password" */ '@/views/authentication/ForgotPassword.vue')
const ResendActivate = () => import(/* webpackChunkName: "app.resend-activation" */ '@/views/authentication/ResendActivate.vue')
const ActivateEmailLoading = () => import(/* webpackChunkName: "app.activate" */ '@/views/authentication/ActivateEmailLoading.vue')
const RecoveryLoading = () => import(/* webpackChunkName: "app.activate" */ '@/views/authentication/RecoveryLoading.vue')
const DeleteTokensLoading = () => import(/* webpackChunkName: "app.revoke-access" */ '@/views/authentication/DeleteTokensLoading.vue')
const ResetPassword = () => import(/* webpackChunkName: "app.reset-password" */ '@/views/authentication/ResetPassword.vue')
const Error500 = () => import(/* webpackChunkName: "error-500" */ '@/views/error/Error500.vue')
const Error404 = () => import(/* webpackChunkName: "error-404" */ '@/views/error/Error404.vue')
const Error403 = () => import(/* webpackChunkName: "error-403" */ '@/views/error/Error403.vue')
const dev = process.env.NODE_ENV !== 'production'

const router = new VueRouter({
  // use hash mode for development purpose
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/login',
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        requiresAuth: true,
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      props: true,
      component: Login,
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/resend-activate',
      name: 'resend-activate',
      component: ResendActivate,
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '/account',
      name: 'account',
      props: true,
      component: Account,
      meta: {
        layout: 'full',
        requiresAuth: true,
      },
    },
    {
      path: '/error-500',
      name: 'error-500',
      component: Error500,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: Error403,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/invitation',
      name: 'invitation',
      component: InviteLoading,
      meta: {
        layout: 'full',
        requiresAuth: true,
      },
    },
        {
      path: '/recovery/:token',
      component: RecoveryLoading,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/account/dashboard/:id',
      name: 'organization',
      props: true,
      component: Analytics,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.analytics'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.analytics'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/workspaces',
      name: 'workspaces',
      props: true,
      component: Workspaces,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.workspaces'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.workspaces'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/records',
      name: 'records',
      props: true,
      component: Records,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.calendar'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.calendar'),
          // active: true,
        },

      },
    },
    {
      path: '/account/dashboard/:id/services',
      name: 'services',
      props: true,
      component: Services,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.services'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.services'),
          // active: true,
        },
      },
    },
    {
      path: '/account/dashboard/:id/clients',
      name: 'clients',
      props: true,
      component: ClientBase,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('analytics.clients-base'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('analytics.clients-base'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/reviews',
      name: 'reviews',
      props: true,
      component: Reviews,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.reviews'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.reviews'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/settings',
      name: 'settings',
      props: true,
      component: Settings,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.settings'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.settings'),
          // active: true,
        },
      },
    },
    {
      path: '/account/dashboard/:id/branch',
      name: 'branch-offices',
      props: true,
      component: BranchOffices,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.branch-offices'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.branch-offices'),
          // active: true,
        },
      },
    },
    {
      path: '/account/dashboard/:id/widget',
      name: 'widget',
      props: true,
      component: Widget,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('widget.widget'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('widget.widget'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/booking-page',
      name: 'customize-booking-page',
      props: true,
      component: OrganizationBookingPage,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('widget.booking-page'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('widget.booking-page'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/payment-settings',
      name: 'payment-settings',
      props: true,
      component: PaymentSettings,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.payment-settings'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.payment-settings'),
        },
      },
    },
    {
      path: '/account/dashboard/:id/payment-history',
      name: 'payment-history',
      props: true,
      component: PaymentHistory,
      meta: {
        layout: 'vertical',
        pageTitle: i18n.t('basic.payment-history'),
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('basic.payment-history'),
        },
      },
    },
    {
      path: '/account/workspace-permitted-access',
      name: 'workspace-page',
      props: true,
      component: WorkspacePage,
      meta: {
        pageTitle: i18n.t('profile.access'),
        layout: 'full',
        requiresAuth: true,
        breadcrumb:
        {
          text: i18n.t('profile.access'),
        },
      },
    },
    {
      path: '/activate/:uid/:token',
      name: 'activate',
      component: ActivateEmailLoading,
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '/revoke-access/:token',
      name: 'revoke-access',
      component: DeleteTokensLoading,
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '/password/reset/confirm/:uid/:token',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        layout: 'full',
        requiresVisitor: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      if (to.name === 'invitation') {
        next({name: 'register', query: to.query})
      } else {
        next({name: 'login'})
      }
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (isLoggedIn) {
      next({
        name: 'account',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
