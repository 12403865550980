import axios from '@axios'

export default {
  state: {
    payments_last_org_id: null,
    paymentsPagination: {},
    payments: [],
    paymentsSettingsPagination: {
      page: 1,
      size: 5,
      order: 'created_at',
      query: '',
    },
    payouts_last_org_id: null,
    payoutsPagination: {},
    payouts: [],
    payoutsSettingsPagination: {
      page: 1,
      size: 5,
      order: 'created_at',
      query: '',
    },
  },
  getters: {
    getPaymentsPagination(state) {
      return state.paymentsPagination
    },
    getPayments(state) {
      return state.payments
    },
    getPaymentsSettingsPagination(state) {
      return state.paymentsSettingsPagination
    },
    getPaymentsLastOrgId(state) {
      return state.payments_last_org_id
    },
    getPayoutsPagination(state) {
      return state.payoutsPagination
    },
    getPayouts(state) {
      return state.payouts
    },
    getPayoutsSettingsPagination(state) {
      return state.payoutsSettingsPagination
    },
    getPayoutsLastOrgId(state) {
      return state.payouts_last_org_id
    },
  },
  mutations: {
    UPDATE_PAYMENTS_PAGINATION(state, data) {
      state.paymentsPagination = data
    },
    UPDATE_PAYMENTS(state, data) {
      state.payments = data
    },
    UPDATE_PAYMENTS_SETTINGS_PAGINATION(state, {key, value}) {
      state.paymentsSettingsPagination[key] = value
    },
    UPDATE_PAYMENTS_LAST_ORG_ID(state, data) {
      state.payments_last_org_id = data
    },
    UPDATE_PAYOUTS_PAGINATION(state, data) {
      state.paymoutsagination = data
    },
    UPDATE_PAYOUTS(state, data) {
      state.payouts = data
    },
    UPDATE_PAYOUTS_SETTINGS_PAGINATION(state, {key, value}) {
      state.payoutsSettingsPagination[key] = value
    },
    UPDATE_PAYOUTS_LAST_ORG_ID(state, data) {
      state.payouts_last_org_id = data
    },
  },
  actions: {
    fetchAccountVerify(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/account/verify/', data).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    fetchPaymentLink(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('payments/stripe/checkout/initiate/', data).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateInternalStatus(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/merchant/update/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStatus(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/account/status/' ,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBalance(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/account/balance/' ,data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayments(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/transactions/payments/${formData.organization}?order=${formData.order}&page=${formData.page}&page_size=${formData.size}&query=${formData.query}`)
          .then(response => {
            context.commit('UPDATE_PAYMENTS_LAST_ORG_ID', formData.organization)
            context.commit('UPDATE_PAYMENTS_PAGINATION', response.data.pagination)
            context.commit('UPDATE_PAYMENTS', response.data.result)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPayouts(context, formData) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/transactions/payouts/${formData.organization}?order=${formData.order}&page=${formData.page}&page_size=${formData.size}&query=${formData.query}`)
          .then(response => {
            context.commit('UPDATE_PAYOUTS_LAST_ORG_ID', formData.organization)
            context.commit('UPDATE_PAYOUTS_PAGINATION', response.data.pagination)
            context.commit('UPDATE_PAYOUTS', response.data.result)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    checkFirstPartPaymentInfo(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/onboarding/check/individual-billing-info/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkCompanyInfo(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/onboarding/check/company-billing-info/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkRepresent(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/onboarding/check/company-representative-info/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCompanyOrIndividualMerchant(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/onboarding/register/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePaymentSettings(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch('/payments/stripe/account/update/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    confirmPayout(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/payments/stripe/payout/initiate/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStripeAccount(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch('/payments/stripe/account/delete/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
}
