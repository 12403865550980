import axios from '@axios'
import { latLng } from 'leaflet'
import { timezones } from "@/libs/timezones";

export default {
  state: {
    centerMap: latLng(50.10252406395657, 14.445648193359377),
    markerPosition: [50.10252406395657, 14.445648193359377],
    zoomMap: 17,
    timezones: timezones,
    getCode: localStorage.getItem('code') || null,
  },
  getters: {
    getCode: state => state.getCode,
    getCenterMap(state) {
      return state.centerMap
    },
    getMarkerPosition(state) {
      return state.markerPosition
    },
    getZoomMap(state) {
      return state.zoomMap
    },
    getTimezones(state) {
      return state.timezones
    },
  },
  mutations: {
    UPDATE_CODE(state, data) {
      localStorage.setItem('code', data)
      state.getCode = data
    },
    updateCenterMap(state, data) {
      state.centerMap = latLng(data)
    },
    updateMarkerPosition(state, data) {
      state.markerPosition = latLng(data)
    },
    updateZoomMap(state, data) {
      state.zoomMap = data
    },
    updateTimezones(state, data) {
      state.timezones = data
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    // fetchTimezones(context) {
    //   if (context.state.timezones === null) {
    //     return new Promise((resolve, reject) => {
    //       axios.get('/organizations/timezones/')
    //         .then(response => {
    //           context.commit('updateTimezones', response.data.timezones)
    //           resolve(true)
    //         })
    //         .catch(error => reject(error))
    //     })
    //   }
    // },
    fetchTimeZoneByLatAnsLng(context, { lat, lng }) {
      return new Promise((resolve, reject) => {
        axios.get('/maps/timezone-by-latlng', {
          params: {
            lat,
            lng,
          },
        }).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    currentCoordinates(context) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            context.dispatch('addressByCoordinates', coords)
              .then(response => resolve(response))
              .catch(error => reject(error))
          },
          // Reject if the user doesn't
          // allow accessing their location.
          error => reject(error),
        )
      })
    },
    addressByCoordinates(context, coords) {
      const ENDPOINT = 'https://nominatim.openstreetmap.org/reverse'
      const FORMAT = 'jsonv2'
      return new Promise((resolve, reject) => {
        axios.get(ENDPOINT, {
          headers: {
            'accept-language': null,
          },
          params: {
            format: FORMAT,
            lat: coords.latitude,
            lon: coords.longitude,
            email: 'support@tobook.link',
          },
        }).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },
    coordinatesByAddress(context, address) {
      const ENDPOINT = 'https://nominatim.openstreetmap.org/search'
      const FORMAT = 'geocodejson'
      return new Promise((resolve, reject) => {
        axios.get(ENDPOINT, {
          headers: {
            'accept-language': null,
          },
          params: {
            format: FORMAT,
            street: address.address,
            city: address.city,
            country: address.country,
            limit: 1,
          },
        }).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },
}
