import axios from '@axios'

export default {
  state: {
    orgGroup: {
      org_id: null,
      groups: [],
    },
  },
  getters: {
    getOrgGroup(state) {
      return state.orgGroup
    },
  },
  mutations: {
    UPDATE_ORG_GROUPS(state, data) {
      state.orgGroup.groups = data
    },
    UPDATE_ORG_GROUPS_ORG_ID(state, data) {
      state.orgGroup.org_id = data
    },
  },
  actions: {
    deleteGroup(context, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/organizations/tags/delete/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchGroups(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/organizations/tags/${id}`)
          .then(response => {
            context.commit('UPDATE_ORG_GROUPS', response.data.tags_by_org)
            context.commit('UPDATE_ORG_GROUPS_ORG_ID', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createGroup(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/organizations/tags/create/${data.id}`, data)
          .then(response => {
            // context.commit('updateOneService', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateGroup(context, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`/organizations/tags/update/${data.id}`, data)
          .then(response => {
            // context.commit('updateOneService', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    }
  },
}
