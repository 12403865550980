import jwtDefaultConfig from './jwtDefaultConfig';
import router from '@/router';
import crypto from '@/libs/crypto';
import i18n from '@/libs/i18n';

export default class JwtService {
  axiosIns = null;
  jwtConfig = { ...jwtDefaultConfig };
  subscribers = [];

  noConnectionWarningShown = false;
  slowConnectionWarningShown = false;

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    this.axiosIns.interceptors.request.use(this.requestInterceptor);
    this.axiosIns.interceptors.response.use(this.responseInterceptor, this.responseErrorInterceptor);
  }

  requestInterceptor = (config) => {

    if (!this.isOnline() && !this.noConnectionWarningShown) {
      // We display a warning to the user that there is no Internet connection
      this.emitEvent('show-alert', { ic: 'RssIcon', color: 'danger', title: i18n.t('error.error'), message: i18n.t('error.no-internet'), timeout: 10000 })
      this.noConnectionWarningShown = true
      return Promise.reject(new Error("Don't have internet connection"));
    }

    if (this.isSlowConnection() && !this.slowConnectionWarningShown) {
      // We display a warning to the user about a slow Internet connection
      this.emitEvent('show-alert', { ic: 'RssIcon', color: 'warning', title: i18n.t('warning.warning'), message: i18n.t('warning.slow-connection'), timeout: 10000 })
      this.slowConnectionWarningShown = true
    }

    const accessToken = this.getToken();
    config.headers["Accept-Language"] = localStorage.getItem("locale") || "en";

    if (accessToken) {
      config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
    }
    return config;
  };

  responseInterceptor = (response) => response;

  responseErrorInterceptor = async (error) => {
    const { response } = error;

    if ([404].includes(response.status)) {
      // router.push({ name: `error-${response.status}` });
      return Promise.reject(error);
    }
    if ([403].includes(response.status)) {
      router.push({ name: `error-${response.status}` });
      return Promise.reject(error);
    }

    if (response && response.status === 401) {
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem('userData');
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  };

  // Additional function for checking the availability of an Internet connection
  isOnline() {
    return window.navigator.onLine;
  }

  // Additional function for checking a slow connection
  isSlowConnection() {
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

    if (connection && connection.effectiveType) {
      return connection.effectiveType === 'slow-2g' || connection.effectiveType === '2g';
    }
    // If the API is unavailable, return false, since we cannot determine if the connection is slow
    return false;
  }

  emitEvent(eventName, data) {
    const event = new CustomEvent(eventName, { detail: data });
    window.dispatchEvent(event);
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return crypto.decryptLocalStorageData(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    crypto.encryptDataAndSaveToLocalStorage(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken(),
    });
  }
}




// import jwtDefaultConfig from './jwtDefaultConfig'
// import router from '@/router'
// import crypto from '@/libs/crypto'
//
// export default class JwtService {
//   // Will be used by this service for making API calls
//   axiosIns = null
//
//   // jwtConfig <= Will be used by this service
//   jwtConfig = { ...jwtDefaultConfig }
//
//   // For Refreshing Token
//   // isAlreadyFetchingAccessToken = false
//
//   // For Refreshing Token
//   subscribers = []
//
//   constructor(axiosIns, jwtOverrideConfig) {
//     this.axiosIns = axiosIns
//     this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
//
//     // Request Interceptor
//     this.axiosIns.interceptors.request.use(
//       config => {
//         // Get token from localStorage
//         const accessToken = this.getToken()
//         config.headers['Accept-Language'] = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en'
//         // If token is present add it to request's Authorization Header
//         if (accessToken) {
//           // eslint-disable-next-line no-param-reassign
//           config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
//         }
//         // if (config.data) {
//         //   console.log('here')
//         //   const cryptoData = Cryptojs.AES.encrypt(JSON.stringify(config.data), process.env.VUE_APP_DEV_ENCRYPTION_KEY).toString()
//         //   console.log(Cryptojs.AES.decrypt(cryptoData, process.env.VUE_APP_DEV_ENCRYPTION_KEY).toString(Cryptojs.enc.Utf8))
//         //   console.log('here_2')
//         // }
//         return config
//       },
//       error => Promise.reject(error),
//     )
//
//     // Add request/response interceptor
//     this.axiosIns.interceptors.response.use(
//       response => response,
//       // eslint-disable-next-line consistent-return
//       async error => {
//         const { response } = error
//
//         if (response.status === 404) {
//           router.push({ name: 'error-404' })
//           return
//         }
//
//         if (response.status === 403) {
//           router.push({ name: 'error-403' })
//           return
//         }
//
//         if (response && response.status === 401) {
//           localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
//           localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
//           localStorage.removeItem('userData')
//           router.push({ name: 'login' })
//         }
//         return Promise.reject(error)
//       },
//     )
//   }
//
//   onAccessTokenFetched(accessToken) {
//     this.subscribers = this.subscribers.filter(callback => callback(accessToken))
//   }
//
//   addSubscriber(callback) {
//     this.subscribers.push(callback)
//   }
//
//   getToken() {
//     // let decryptToken = ''
//     // if (localStorage.getItem(this.jwtConfig.storageTokenKeyName)) {
//     //   decryptToken = Cryptojs.AES.decrypt(localStorage.getItem(this.jwtConfig.storageTokenKeyName), process.env.VUE_APP_DEV_ENCRYPTION_KEY).toString(Cryptojs.enc.Utf8)
//     // }
//     return crypto.decryptLocalStorageData(this.jwtConfig.storageTokenKeyName)
//   }
//
//   getRefreshToken() {
//     return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
//   }
//
//   setToken(value) {
//     crypto.encryptDataAndSaveToLocalStorage(this.jwtConfig.storageTokenKeyName, value)
//     // const encryptToken = Cryptojs.AES.encrypt(value, process.env.VUE_APP_DEV_ENCRYPTION_KEY).toString()
//     // localStorage.setItem(this.jwtConfig.storageTokenKeyName, encryptToken)
//   }
//
//   setRefreshToken(value) {
//     localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
//   }
//
//   login(...args) {
//     return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
//   }
//
//   register(...args) {
//     return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
//   }
//
//   // поменял refreshToken на refresh
//   refreshToken() {
//     return new Promise((resolve, reject) => {
//       this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
//         refresh: this.getRefreshToken(),
//       }).then(rs => resolve(rs)).catch(e => reject(e))
//     })
//   }
// }
